import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Typography,
  createTheme,
  ThemeProvider,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { setInformation } from "./../redux/slices/public";
import { useTranslation } from "react-i18next";

import Cereza from "../assets/brand/cerezaBlock.png";
import SpinnerCerezaColor from "../assets/brand/SpinnerCerezaColor.svg";
import i18n from "i18next";
import styles from "./index.module.css";
import "animate.css";

export const TemplateAuth = ({ children, spinner, transition }) => {
  const { t } = useTranslation(["auth"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [customUI, setCustomUI] = useState(null);
  const [welcomeChildren, setWelcomeChildren] = useState(false);
  const [byeLogo, setByeLogo] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [alignment, setAlignment] = useState(i18n?.language);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: customUI?.customColors?.mainColor ?? "#de008b",
      },
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/rec/public/info`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Realm: "cereza",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const data = await response.json();
        dispatch(setInformation(data));
        setCustomUI({
          customColors: data?.customColors,
          logoLogin: data?.logoLogin,
        });

        if (windowWidth <= 940) {
          setTimeout(() => {
            setByeLogo(true);
          }, 3000);
          setTimeout(() => {
            setWelcomeChildren(true);
          }, 4000);
        }
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };
    fetchData();
  }, []);

  const changeLanguage = async (lng) => {
    if (i18n && i18n.changeLanguage) {
      i18n.changeLanguage(lng);
    }
  };

  const footer = () => {
    return (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={windowWidth <= 450 ? 2 : 8}
        m={windowWidth <= 450 ? 0 : 4}
      >
        <Box
          sx={{ maxWidth: "200px", cursor: "pointer" }}
          onClick={() => navigate("/terms")}
        >
          <Typography variant="body2" color="text.secondary" align="center">
            {t("auth:termsAndConditions")}
          </Typography>
        </Box>
        <Box
          sx={{ maxWidth: "200px", cursor: "pointer" }}
          onClick={() => navigate("/policy")}
        >
          <Typography variant="body2" color="text.secondary" align="center">
            {t("auth:Policy")}
          </Typography>
        </Box>
      </Stack>
    );
  };

  const lenguage = () => {
    return (
      <>
        <Typography
          variant="caption"
          display="block"
          style={{ marginBottom: "0px", marginTop: "1rem" }}
          gutterBottom
        >
          {t("auth:Idiomas")}
        </Typography>
        <ToggleButtonGroup
          style={{
            marginInlineEnd: "1rem",
          }}
          value={alignment}
          exclusive
          onChange={handleAlignment}
          size="small"
        >
          <ToggleButton
            value="es"
            aria-label="ES"
            onClick={() => changeLanguage("es")}
          >
            ES
          </ToggleButton>
          <ToggleButton
            value="en"
            aria-label="EN"
            onClick={() => changeLanguage("en")}
          >
            EN
          </ToggleButton>
          <ToggleButton
            value="fr"
            aria-label="FR"
            onClick={() => changeLanguage("fr")}
          >
            FR
          </ToggleButton>
          <ToggleButton
            value="pt"
            aria-label="PT"
            onClick={() => changeLanguage("pt")}
          >
            PT
          </ToggleButton>
        </ToggleButtonGroup>
      </>
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          className={styles.myBody}
          style={{
            background: `linear-gradient(to right, ${
              customUI?.customColors?.loginGradient?.initialColor ?? "#F5DBEBCC"
            }, ${
              customUI?.customColors?.loginGradient?.finalColor ?? "#F5D8DDE5"
            })`,
          }}
        >
          {spinner || customUI === null ? (
            <div className={styles.spinner}>
              <img
                src={SpinnerCerezaColor}
                alt="spiner"
                className={styles.spinnerImage}
              />
            </div>
          ) : (
            <div
              transition-style={transition ? "out:circle:hesitate" : ""}
              className={`${styles.bodyBackGround} ${
                transition ? "" : "animate__zoomInDown"
              }`}
              style={
                window.location.pathname !== "/auth/recover-password"
                  ? {
                      borderRadius: "30px 3px 3px 30px",
                      gridTemplateColumns: "1fr 1fr",
                    }
                  : { borderRadius: "30px", gridTemplateColumns: "1fr" }
              }
            >
              {welcomeChildren || windowWidth > 940 ? (
                <div
                  className={styles.form}
                  style={{
                    gridTemplateRows:
                      windowWidth <= 450 ? "auto 1fr auto" : "1fr auto",
                  }}
                >
                  {windowWidth <= 450 && (
                    <div className={styles.lenguageMobile}>{lenguage()}</div>
                  )}
                  {children}
                  {windowWidth <= 940 && footer()}
                </div>
              ) : (
                <div
                  className={styles.imgMobile}
                  transition-style={byeLogo ? "out:diamond:hesitate" : ""}
                  style={{
                    backgroundColor:
                      customUI?.customColors?.mainColor ?? "#de008b",
                  }}
                >
                  <img
                    src={
                      customUI?.logoLogin === "" ||
                      customUI?.logoLogin === undefined
                        ? Cereza
                        : customUI?.logoLogin
                    }
                    alt="cereza"
                  />
                </div>
              )}
              {window.location.pathname !== "/auth/recover-password" && (
                <div
                  className={styles.geometry}
                  style={{
                    background: customUI?.customColors?.mainColor ?? "#de008b",
                  }}
                >
                  <div className={styles.lenguage}>{lenguage()}</div>
                  <div style={{ display: "grid", placeItems: "center" }}>
                    {window.location.pathname !== "/auth/message" && (
                      <p className={styles.text} style={{ fontSize: "3rem" }}>
                        {t("auth:Bienvenido")}
                      </p>
                    )}
                    <img
                      src={
                        customUI?.logoLogin === "" ||
                        customUI?.logoLogin === undefined
                          ? Cereza
                          : customUI?.logoLogin
                      }
                      alt="cereza"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={styles.stack}>{footer()}</div>
        </div>
      </ThemeProvider>
    </>
  );
};
