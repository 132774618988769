import { TemplateAuth } from "../TemplateAuth";
import { useTranslation } from "react-i18next";

import Hola from "../../assets/hola.PNG";
import styles from "../index.module.css";

export const Message = () => {
  const { t } = useTranslation(["auth"]);

  return (
    <>
      <TemplateAuth spinner={false} transition={false}>
        <div style={{ display: "grid", placeItems: "center" }}>
          <p
            className={styles.text}
            style={{
              fontSize: "2rem",
              marginBottom: "0px",
              textAlign: "center",
            }}
          >
            {t("auth:RegistroExitoso")}
          </p>
          <img src={Hola} alt="Hola" style={{ width: "80%" }} />
          <p
            className={styles.text}
            style={{ marginTop: "0px", textAlign: "center" }}
          >
            {t("auth:ParrafoUno")}
          </p>
          <p className={styles.text} style={{ textAlign: "center" }}>
            {t("auth:ParrafoDos")}
          </p>
          <p
            className={styles.text}
            style={{ width: "100%", textAlign: "center" }}
          >
            {t("auth:ParrafoTres")}
          </p>
        </div>
      </TemplateAuth>
    </>
  );
};
