import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Signup, Policy, Terms, Message } from "./auth";

import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={"/signup"} element={<Signup />} />
        <Route path={"/policy"} element={<Policy />} />
        <Route path={"/terms"} element={<Terms />} />
        <Route path={"/message"} element={<Message />} />
        <Route path="*" element={<Navigate to="/signup" />} />
      </Routes>
    </Router>
  );
}

export default App;
