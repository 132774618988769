import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import {
  UITextField,
  UIButton,
  UICheckBox,
  UIPhoneNumber,
} from "../components";
import { TemplateAuth } from "./TemplateAuth";
import { tenantSignUp } from "../api/auth";
import ReCAPTCHA from "react-google-recaptcha";
import imgError from "../assets/imgError.png";
import * as Yup from "yup";

import styles from "./index.module.css";
import "animate.css";

export const Signup = () => {
  const { t } = useTranslation(["auth"]);

  const [spinner, setSpinner] = useState(false);
  const [transition, setTransition] = useState(false);
  const [error, setError] = useState(false);
  const [interestPlan, setInterestPlan] = useState("");
  const [verifed, setVerifed] = useState(false);

  const captcha = useRef(null);
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const handleRecaptchaChange = (value) => {
    captcha.current.getValue() ? setVerifed(true) : setVerifed(false);
  };

  const validations = Yup.object().shape({
    workEmail: Yup.string()
      .required(t("auth:workEmailRequired"))
      .email(t("auth:workEmailEmail")),
    workEmailConfirm: Yup.string()
      .oneOf([Yup.ref("workEmail"), null], t("auth:workEmailConfirmOneOf"))
      .required(t("auth:workEmailConfirmRequired")),
    firstName: Yup.string()
      .required(t("auth:firstNameRequired"))
      .max(63, t("auth:firstNameMax")),
    lastName: Yup.string().required(t("auth:lastNameRequired")),
    accountName: Yup.string()
      .required(t("auth:accountNameRequired"))
      .matches(/^[a-zA-Z0-9-\s\S]*$/, {
        excludeEmptyString: true,
      })
      .min(1, t("auth:accountNameMin"))
      .max(63, t("auth:accountNameMax")),
    phoneNumber: Yup.string().required(t("auth:phoneNumberRequired")),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      t("auth:termsAndConditionsOneOf")
    ),
  });

  useEffect(() => {
    const urlActual = window.location.href;
    const parametrosDeConsulta = new URLSearchParams(urlActual.split("?")[1]);
    const plan = parametrosDeConsulta.get("plan");
    if (plan) {
      setInterestPlan(plan);
    } else {
      setInterestPlan("Trial");
    }
  }, []);

  const handleSubmit = async (values) => {
    delete values?.workEmailConfirm;
    const updatedValues = {
      ...values,
      interestPlan: interestPlan,
    };
    try {
      if (verifed) {
        await tenantSignUp(updatedValues);
        setTransition(true);

        setTimeout(() => {
          setSpinner(true);
        }, 2500);

        setTimeout(() => {
          window.location.href = "/message";
        }, 10000);
      }
    } catch (error) {
      setError(true);
      console.error("error", error);
    }
  };

  return (
    <TemplateAuth spinner={spinner} transition={transition}>
      {error ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <img src={imgError} alt="imgError" style={{ width: "100%" }} />
          <p className={styles.text} style={{ textAlign: "center" }}>
            {t("auth:Error")}
          </p>
          <p className={styles.text} style={{ textAlign: "center" }}>
            {t("auth:ErrorDos")}
          </p>
          <p className={styles.text} style={{ textAlign: "center" }}>
            {t("auth:ErrorTres")}
          </p>
          <br />
          <UIButton
            label={t("auth:LetsGo")}
            onClick={() => window.location.reload()}
          />
        </div>
      ) : (
        <Formik
          initialValues={{
            workEmail: "",
            workEmailConfirm: "",
            accountName: "",
            phoneNumber: "",
            lastName: "",
            firstName: "",
            termsAndConditions: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={validations}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                  <p
                    className={styles.text}
                    style={{
                      fontSize: "3rem",
                      color: "#212529",
                      margin: "0px",
                    }}
                  >
                    {t("auth:SignUp")}
                  </p>
                </Grid>
                <Grid item={true} xs={12}>
                  <UITextField
                    type="email"
                    formik={formik}
                    label={t("auth:email")}
                    name={"workEmail"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <UITextField
                    type="email"
                    formik={formik}
                    label={t("auth:confirmEmail")}
                    name={"workEmailConfirm"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <UITextField
                    formik={formik}
                    label={t("auth:firstName")}
                    name={"firstName"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <UITextField
                    formik={formik}
                    label={t("auth:lastName")}
                    name={"lastName"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <UIPhoneNumber
                    formik={formik}
                    label={t("auth:phoneNumber")}
                    name={"phoneNumber"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <UITextField
                    formik={formik}
                    label={t("auth:companyName")}
                    name={"accountName"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={siteKey}
                    className={styles.recaptchaContainer}
                    onChange={handleRecaptchaChange}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <div className={styles.Terms}>
                    <UICheckBox
                      label={t("auth:termsAndConditions")}
                      name={"termsAndConditions"}
                      checked={formik.values?.termsAndConditions || false}
                      disabled={formik.isSubmitting}
                    />
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.text}
                      style={{
                        fontSize: "0.8rem",
                        cursor: "pointer",
                        color: "#212529",
                      }}
                    >
                      {t("auth:aquí")}
                    </a>
                  </div>
                </Grid>
                <Grid item={true} xs={12}>
                  <UIButton
                    type="submit"
                    style={{ height: "56px" }}
                    label={t("auth:SignUp")}
                    disabled={!formik.isValid || !verifed}
                    loading={formik.isSubmitting}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </TemplateAuth>
  );
};
