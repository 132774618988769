import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  information: {},
  formPassword: {
    email: "",
    pin: "",
  },
  step: 0,
};

const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    setInformation: (state, action) => {
      state.information = action.payload;
    },
    setEmail: (state, action) => {
      state.formPassword.email = action.payload;
    },
    setPIN: (state, action) => {
      state.formPassword.pin = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

export const { setInformation, setEmail, setPIN, setPassword, setStep } =
  publicSlice.actions;

export const publicSelect = (state) => state.public;

export default publicSlice.reducer;
